import React, { useEffect, useState } from "react";
import { BASE_URL } from "../constants/url";
import { Grid } from "@mui/material";

const headingStyle = {
  fontFamily: "inter",
  fontWeight: 600,
  fontSize: 40,
  color: "purple",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "50px",
};

const videoDescriptionStyle = {
  maxWidth: "400px",
  marginTop: "3px",
  wordBreak: "break-all",
  maxHeight: "50px", 
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 2, 
  WebkitBoxOrient: "vertical",
};

function getEmbededURL(videoURL) {
  let videoId = videoURL.split("/")[3];
  const vQueryPos = videoId.indexOf("?v");
  if (vQueryPos !== -1) {
    const ampersandPos = videoId.indexOf("&");
    if (ampersandPos !== -1) {
      videoId = videoId.substring(vQueryPos + 3, ampersandPos);
    } else {
      videoId = videoId.substring(vQueryPos + 3, videoId.length);
    }
  }

  return `https://www.youtube.com/embed/${videoId}`;
}

function Video() {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${BASE_URL}/videos`)
      .then((response) => response.json())
      .then((data) => {
        setVideos(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <h1 style={headingStyle}>Training Videos</h1>
      <Grid
        container
        spacing={2}
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {videos.map((video, index) => (
              <Grid item key={index}>
                <iframe
                  width="400px"
                  height="225px"
                  src={getEmbededURL(video.link)}
                  allowFullScreen
                ></iframe>
                <h3 style={videoDescriptionStyle}>
                  {video.name}
                </h3>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </div>
  );
}

export default Video;
